<template>
    <div>
        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Объекты</span>
            </v-col>
            <v-col>
                <AddRealestateForm/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                    <v-text-field
                            label="Адрес"
                            placeholder="Введите данные для поиска"
                            v-model="searchString"
                            @keyup.enter="get"
                            hint="Несколько слов разделять пробелом"
                    ></v-text-field>
                        <v-text-field
                                label="Кадастровый номер"
                                placeholder="Введите данные для поиска"
                                v-model="kadNumberString"
                                @keyup.enter="get"
                                hint="Точное совпадение"
                        ></v-text-field>
                        <v-row
                                align="center"
                                justify="space-around"
                                class="mt-5"
                        >
                            <v-btn
                                    depressed
                                    @click="resetForm"
                            >
                                Сбросить
                            </v-btn>
                            <v-btn
                                    depressed
                                    color="primary"
                                    @click="get"
                            >
                                Применить
                            </v-btn>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                        loading-text="Загрузка данных... Пожалуйста подождите"
                >
                    <template v-slot:item.fulladdress="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditRealestates', params: { id: item.estateid }}">{{ item.fulladdress }}</router-link>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import RealestateDataService from "@/services/RealestateDataService";
    import AddRealestateForm from "@/components/AddRealestateForm";


    export default {
        name: "RealestatesTable",
        components: {AddRealestateForm},
        data() {
            return {
                searchString: '', //Строка поиска
                kadNumberString:'', //Строка поиска - Кадастровый номер
                loading: true,
                headers: [
                    {
                        text: 'Адрес',
                        value: 'fulladdress',
                    },
                    {
                        text: 'Кадастровый номер',
                        value: 'kadnumber',
                        width: 200
                    },
                ],
                items: [],
            }
        },
        mounted() {
            //read Localstorage
            if (localStorage.realestatessearchstring) {
                this.searchString = localStorage.realestatessearchstring
            }
            //read Localstorage
            if (localStorage.kadnumberstring) {
                this.kadNumberString = localStorage.kadnumberstring
            }

            //Запрашиваем данные с сервера
            this.get()
        },

        methods: {
            resetForm: function () {
                this.searchString = ''
                this.kadNumberString = ''
            },
            get: function () {
                //Save Localstorage
                localStorage.realestatessearchstring = this.searchString
                localStorage.kadnumberstring = this.kadNumberString

                this.loading = true
                RealestateDataService.find(this.searchString, this.kadNumberString)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        },
    }
</script>

<style scoped>

</style>