<template>
  <div>
    <RealestatesTable/>
  </div>
</template>

<script>
  // @ is an alias to /src
  import RealestatesTable from '@/components/RealestatesTable.vue'

  export default {
    name: 'Realestates',
    components: {
      RealestatesTable
    }
  }
</script>
